// Fix for IE10 and Windows Mobile
if (navigator.userAgent.match(/IEMobile\/10\.0/)) {
    var msViewportStyle = document.createElement('style');
    msViewportStyle.appendChild(
        document.createTextNode(
            '@-ms-viewport{width:auto!important}'
        )
    );
    document.querySelector('head').appendChild(msViewportStyle);
}

// Plyr
const players = Array.from(document.querySelectorAll('.js-player')).map(p => new Plyr(p));

// Parse "dd-mm-yyyy" string to DateTime
function convertDateToDateTime(date) {
    let [day, month, year] = date.split('-');
    return year + "-" + month + "-" + day + " " + "00:00:00";
}

// Document ready
$(document).ready(function () {

    // Tooltip
    $('[data-toggle="tooltip"]').tooltip();

    // Mobile navtoggle Media
    $('.navtoggle-media').click(function () {
        $('.media-menu').slideToggle();
        $(this).find('.fa').toggleClass('fa-chevron-down fa-chevron-up');
    });

    // Mobile navtoggle Over ons
    $('.navtoggle-overons').click(function () {
        $('.over-ons-menu').slideToggle();
        $(this).find('.fa').toggleClass('fa-chevron-down fa-chevron-up');
    });

    // Old browser detection
    var $buoop = {c: 2};

    function $buo_f() {
        var e = document.createElement("script");
        e.src = "//browser-update.org/update.js";
        document.body.appendChild(e);
    };
    try {
        document.addEventListener("DOMContentLoaded", $buo_f, false)
    }
    catch (e) {
        window.attachEvent("onload", $buo_f)
    }

});
